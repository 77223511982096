import $axios from "../plugins/axios";

const UserService = {
  async changeRole(payload){
      try{
          const {data} = await $axios.put(`change-role`,payload);
          return data;
      }catch(err){
          return err;
      }
  },
  async deleteUserFromHotel(user_id){
      try{
          const {data} = await $axios.get(`remove-roles/${user_id}`);
          return data;
    }catch (err){
          return err;
      }
  },
    async getUserList(hotel_id){
      try{
          const {data} = await $axios.get(`get-managers-and-receptions/${hotel_id}`);
          return data;
      }catch(e){
          return e;
      }
    }
};
export default UserService;